import React from "react";
import { Form, Message, Input,Checkbox } from "semantic-ui-react";
import api from "../../api";
import ConfirmDialog from "../Modal/ConfirmDialog";

class GlobalConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zapthreads: {abandoned:false,interacted:true},
      stthreads: {abandoned:false,interacted:true},
      successMessage: null,
      errors: null,
      inputkey:1,
      loading:false,
      modalIsOpen:false
    };
    this.changeNotifyVia=this.changeNotifyVia.bind(this);
  }
  componentDidMount() {
    this.loadData();
  }
  loadData=()=>{
    
      api.servicetitan.getFinalconfig({}).then(resp=>{
        if(resp.status){
          this.setState({zapthreads:resp.data?.zap_status ? resp.data?.zap_status:{abandoned:false,interacted:true} ,stthreads:resp.data?.st_status ? resp.data?.st_status:{abandoned:false,interacted:true},inputkey:Math.random()});  
        }
    });
  }
 
  validatechecks=(e)=>{
    if((this.state.zapthreads.abandoned==false && this.state.zapthreads.interacted==false) ||(this.state.stthreads.abandoned==false && this.state.stthreads.interacted==false) ){
       this.setState({modalIsOpen:true}); 
    }else{
      this.finalSave(e);
    }
  }
  

  changeNotifyVia=(fieldname,type) =>{
    let notifyvia = type==1 ? this.state.stthreads : this.state.zapthreads;
    notifyvia[fieldname] = !notifyvia[fieldname];
    if(type==2) {
        this.setState({zapthreads:notifyvia});
    }else{
      this.setState({stthreads:notifyvia});
    }
   
}

finalSave = (e) =>{
  this.setState({loading:true});
  let formData={
      stchatstatus:this.state.stthreads,
      zapchatstatus:this.state.zapthreads
  }
  api.servicetitan.saveFinalconfig(formData).then(resp=>{
      if(resp.status){
         this.setState({successMessage:"Configuration saved.",loading:false});  
      }else{
        this.setState({loading:false,successMessage:null,errors:"There is a error."});
      }
      setTimeout(() => {
        this.setState({loading:false,successMessage:null,errors:null});
      }, 4000);
  });
}
closeModal=(e) =>{
  this.setState({modalIsOpen:false});  
} 

savenow=(e) =>{
  this.setState({modalIsOpen:false}); 
  this.finalSave(e); 
} 

  
  render() {
    const errorLabel = <div className="red" pointing={"true"} />;

    return (
      <React.Fragment>
        <div className="row">
        {this.state.loading && <div className="ui loading form"></div>}
          <div className="col-lg-12 col-md-12 col-sm-12">
            {this.state.successMessage && (
              <Message color={"green"}>{this.state.successMessage}</Message>
            )}
            <div className="kt-portlet__body kt-portlet__body--fit ">
              <div class="form-group row">
              <div style={{display:"flex", justifyContent:"space-between"}} class="col-lg-16 col-md-12 col-sm-12 form-group-sub d-flex  flex-column">
                            <p><label>Send Call/Chat for Service Titan</label></p>
                            <div style={{display:'flex',flexDirection:'row'}} key={this.state.inputkey}>
                            <Checkbox
                                  
                                  name="threads"
                                  label={{ children: 'Abandoned' }}
                                  onChange={() => this.changeNotifyVia('abandoned',1)}
                                  defaultChecked={this.state.stthreads.abandoned==true ? true : false}
                                />
                                <Checkbox   name="notifyvia" style={{marginLeft:'50px'}} label={{ children: 'Interacted' }} onChange={() => this.changeNotifyVia('interacted',1)} defaultChecked={this.state.stthreads.interacted ? true:false} />
                            </div>
                            </div>
                            <div style={{display:"flex", justifyContent:"space-between"}} class="col-lg-16 col-md-12 col-sm-12 form-group-sub d-flex  flex-column">
                            <p><label>Send Call/Chat for Zapier</label></p>
                            <div style={{display:'flex',flexDirection:'row'}} key={this.state.inputkey}>
                            <Checkbox
                                  name="threads"
                                  label={{ children: 'Abandoned' }}
                                  onChange={() => this.changeNotifyVia('abandoned',2)}
                                  defaultChecked={this.state.zapthreads.abandoned}
                                />
                                <Checkbox   name="notifyvia" style={{marginLeft:'50px'}} label={{ children: 'Interacted' }} onChange={() => this.changeNotifyVia('interacted',2)} defaultChecked={this.state.zapthreads.interacted} />
                            </div>
                            
                              

                            </div>
              </div>
              
              <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub mt-5">
                                <button
                                type="submit"
                                style={{ float: "right" }}
                                className="btn linear-gradient yr-submitbtn btn-sm"
                                onClick={(e)=>this.validatechecks(e)}
                                >
                                Save
                                </button>
                            </div>
            </div>
          </div>
        </div>
        { this.state.modalIsOpen  && <ConfirmDialog
                show={this.state.modalIsOpen }
                toggleModal={this.savenow}
                closeAction={this.closeModal}
                title="Confirm your action"
                message={"If you leave both checkboxes unchecked, it may stop feeding data to zap/service-titan. Do you really want to save configuration?"}
              />
        }
      </React.Fragment>
    );
  }
}

export default GlobalConfig;
