import axios from "../../config/axios";
import config from "../../config/index";

const dialogflow = {
  loadDialogFlow: (params) =>
    axios
      .post("/integration/GoogleMyBusiness/", params)
      .then((resp) => (resp ? resp.data : null)),

  createWebhook: (params) =>
    axios
      .post("/integration/GoogleMyBusiness/create", params)
      .then((resp) => (resp ? resp.data : null)),

      getcustomWebhook: (params) =>
        axios
          .get(`${config.apiBaseURL2}getCustomWebhoook`, params)
          .then((resp) => (resp ? resp.data : null)),

      saveCustomWebhoook: (params) =>
        axios
          .post(`${config.apiBaseURL2}saveCustomWebhoook`, params)
          .then((resp) => (resp ? resp.data : null)), 
          
        searchCustomWebhoook: (params) =>
            axios
              .get(`${config.apiBaseURL2}sendwebhookdata`, params)
              .then((resp) => (resp ? resp.data : null)),    
          
               

//   deleteWebhook: (params) =>getCustomWebhook
//     axios
//       .post("/integration/googlemybusiness/delete", params)
//       .then((resp) => (resp ? resp.data : null)),
};

export default dialogflow;