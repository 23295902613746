import React from 'react';
import CkEditorTextbox from './CkEditorTextbox';
import { ReactSortable } from "react-sortablejs";
import { Message } from "semantic-ui-react";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import CustomErrorField from "../../../../Form/CustomErrorField";
import api from "../../../../../api";
import Modal from "../../../ChatSetup/components/Modal";
import { Multiselect } from "multiselect-react-dropdown";
import { Dropdown } from 'semantic-ui-react'
const capturedListArr = [
    { key: 'name', val: 'Name' },
    { key: 'phonenumber', val: 'Phone No' },
    { key: 'email', val: 'Email Id' },
    { key: 'zipcode', val: 'Zip Code' },
    { key: 'locationname', val: 'Street 1' },
    { key: 'locationstreet', val: 'Street 2' },
    { key: 'locationcity', val: 'City' },
    { key: 'locationstate', val: 'State' }
];
class BranchDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userToaster: false,
            userToasterMsg: "",
            userToasterColor: "",
            loading: false,
            dialogCaptured: false,
            dialogCapturedLoading: false,
            dialogCapturedMessage: '',
            dialogCapturedAction: '',
            capturedText: '',
            editor_state: [],
            errors: [],
            additinal_captured_error: "",
            branchHasButton: false,
            modalstatus: false,
            modaltitle: "",
            modalbody: "",
            contact_bucket: [],
            selectedValue: [],
            checkedValue: [],
            callRule:'',
           
           
           
        };

    }
deletezapBranch=(ac,param, index)=>{
    this.props.popupClose()
    this.props.actionwebhookapi('delete', param, index)
}

   
    getBranchNameById(branchId) {
        console.log(branchId);
        let branches = this.props.branchList;
        for (let i = 0; i < branches.length; i++) {
            console.log(branches[i].branch_id);
            if (branches[i].branch_id === branchId) {
                return branches[i].branch_name;
            }
        }
        return null; // Return null if no matching branch ID is found
    }

    componentDidMount() {
        this.loadTextArea();
        this.setState({
            loading: this.props.loadingaction,
            accept_attachment: this.props.branchDetails.details.attachment_on_off,
            contact_bucket: this.props.contact_bucket,
            selectedValue: this.props.checkedValue,
           
        });
        const rdwimagewrapper = document.querySelector('.rdw-image-wrapper');
        if (rdwimagewrapper) {
            rdwimagewrapper.addEventListener('click', () => {
                setTimeout(() => {
                    document.querySelector('.rdw-image-modal-url-input').setAttribute('placeholder', 'Enter Link');
                }, 500)
            })
        }

        this.checkBranchHasButton(this.props.branchDetails.branch_buttons, (st) => {
            this.setState({ branchHasButton: st });
        });
        
    }

    // onEditorStateChange = (editorState) => {
    //     this.setState({
    //         editorState,
    //     });
    // };

    componentDidUpdate(prevProps) {

        if (this.props.branchDetails.details.branch_id != prevProps.branchDetails.details.branch_id) {

            this.loadTextArea();
            this.setState({ loading: this.props.loadingaction, accept_attachment: this.props.branchDetails.details.attachment_on_off })
            this.checkBranchHasButton(this.props.branchDetails.branch_buttons, (st) => {
                this.setState({ branchHasButton: st })
            });
        } else {

            if (this.props.editortrue != prevProps.editortrue) {

                this.setState({ accept_attachment: this.props.branchDetails.details.attachment_on_off })
                this.loadTextArea();

            }
        }
        if (this.props.indextime != prevProps.indextime) {

            this.setState({ accept_attachment: this.props.branchDetails.details.attachment_on_off }, () => {

                this.checkBranchHasButton(this.props.branchDetails.branch_buttons, (st) => {
                    this.setState({ branchHasButton: st })
                });
            })


        }

    }

    // remove extra editorexist
    removeTags = (str) =>{
        // let 
        if ((str===null) || (str===''))
            return str;
        else
            str = str.toString();
              
        if(!str.includes('<div class=editorexist>') && !str.includes('<div class="editorexist">') && !str.includes(`<div class='editorexist'>`)){
          return str;
        }
        
        let splitStatus = false;
        let splitString = null;
        
        if(str.split('<div class=editorexist>').length == 1){
          splitStatus = false;
        }else{
          splitStatus = true;
          splitString = str.split('<div class=editorexist>');
        }
        if(str.split('<div class="editorexist">').length == 1){
          splitStatus = false;
        }else{
          splitStatus = true;
          splitString = str.split('<div class="editorexist">');
        }
        if(str.split(`<div class='editorexist'>`).length == 1){
          splitStatus = false;
        }else{
          splitStatus = true;
          splitString = str.split(`<div class='editorexist'>`);
        }
        if(splitStatus && splitString.length <= 2){
          return str;
        }
        
        // remove blank
        const newstrA = splitString.filter((str, i) => str != '').join('');
        // now remove closing tag </div>  
        const closingDivLength = 6*(splitString.length - 1);
        let aa = newstrA.slice(-closingDivLength);

        // </div> start index
        const closingTagIndex = newstrA.indexOf(aa);

        // remove </div> from string
        const newtext = newstrA.slice(0,closingTagIndex);

        return `<div class=editorexist>${newtext}</div>`;
    }

    loadTextArea = () => {

        this.props.branchDetails.branch_questions && this.props.branchDetails.branch_questions.map((obj2, index) => {
            let html = obj2.question;
            // check html with tags if it has multiple <div class="editorexist"> then remove
            html = this.removeTags(html);
            let contentBlock = htmlToDraft(html);
            if (contentBlock) {
                let contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                let editorState = EditorState.createWithContent(contentState);
                let sta = this.state.editor_state;
                sta[index] = editorState;
                this.setState({
                    editor_state: sta,
                }, function () {
                    // console.log(this.state.editor_state)
                });
            }



        });
    }


    onEditorStateChange = (editorState, index) => {

        let sta = this.state.editor_state;
        sta[index] = editorState;

        let editorSourceHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        if (editorSourceHTML.slice(3, -4).length > 1) {
            let errors = [];
            this.setState({ errors });
        }

        this.setState({
            editor_state: sta,
        }, function () {
            this.props.textAreaAction(editorSourceHTML, 'textchange', index);
        });
    };

    saveBranch = () => {
        let data = {};

        let errors = [];

        new Promise(resolve => {

            if (this.props.branchDetails.details.branch_name == '') {
                let arr = [];
                arr['0'] = "This field is required";
                errors.title_error = arr;
            }

            this.props.branchDetails.branch_questions.forEach((element, index) => {

                let ques = element.question.replace(/(<([^>]+)>)/ig, '');


                if (ques.replace(/\&nbsp;/g, '').trim() == '') {

                    let arr = [];
                    arr[index] = "This field is required";
                    errors.question_error = arr;

                }
            });

            if (this.props.branchDetails.details.team_workspace_members == 1) {

                this.props.branchDetails.team_data.forEach((element, index2) => {

                    var valueArr = this.props.branchDetails.team_data.map(function (item) { return item });
                    valueArr.splice(index2, 1);

                    if (element.email == '' && element.phone == '') {

                        let arr = [];
                        arr[index2] = "Please select at least phone or email";
                        if (typeof errors.email_phone_error != 'undefined') {
                            errors.email_phone_error.push(arr);
                        } else {
                            errors.email_phone_error = arr;
                        }


                    }
                    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

                    if (element.email != '') {

                        if (!reg.test(element.email)) {

                            let arr = [];
                            arr[index2] = "Please enter valid email address";
                            //errors.email_error=arr;

                            if (typeof errors.email_error != 'undefined') {
                                errors.email_error.push(arr);
                            } else {
                                errors.email_error = arr;
                            }

                        }



                        let activeindex = valueArr.findIndex(
                            (list) => list.email === element.email
                        );

                        if (activeindex != -1) {
                            let arr = [];
                            arr[index2] = "This is duplicate value";
                            //errors.email_error=arr;

                            if (typeof errors.email_error != 'undefined') {
                                errors.email_error.push(arr);
                            } else {
                                errors.email_error = arr;
                            }
                        }
                    }







                    if (element.phone != '') {

                        if (element.phone.length < 13) {
                            let arr = [];
                            arr[index2] = "Please enter valid phone number";

                            errors.phone_error = arr;
                        }

                        let activeindexphone = valueArr.findIndex(
                            (list) => (list.phone === element.phone && list.country_code == element.country_code)
                        );


                        if (activeindexphone != -1) {
                            let arr = [];
                            arr[index2] = "This is duplicate value";
                            //errors.email_error=arr;

                            if (typeof errors.phone_error != 'undefined') {
                                errors.phone_error.push(arr);
                            } else {
                                errors.phone_error = arr;
                            }
                        }





                    }


                });
            }


            resolve(errors);
        }).then((errors) => {


            if (Object.keys(errors).length == 0) {
                this.setState({ loading: true });
                this.props.saveBranch(data, (data_parm) => {

                    let scrolltop = document.querySelector(".addEditBranch").offsetTop - document.body.scrollTop;
                    window.scroll({ top: scrolltop, left: 0, behavior: 'smooth' });
                    // var elem = document.querySelector('.addEditBranch');
                    // elem.scrollIntoView();
                    this.setState({
                        userToaster: true,
                        userToasterMsg: "Branch updated successfully",
                        userToasterColor: "green",
                        loading: false,
                        errors: []
                    }, function () {
                        setTimeout(
                            function () {
                                this.setState({ userToaster: false });
                            }.bind(this),
                            3000
                        );
                    });
                });
            } else {


                this.setState({ errors: errors }, function () {

                    Object.keys(errors).forEach((element, index) => {

                        if (index == 0) {

                            let scrolltop = document.querySelector(".focusError").offsetTop - document.body.scrollTop;

                            window.scroll({ top: scrolltop, left: 0, behavior: 'smooth' });
                        }


                    });

                })

                // var elem = document.querySelector('.addEditBranch');
                // elem.scrollIntoView();
            }
        })



    }

    deleteButton = () => {

    }

    parseHtmlString = (yourHtmlString) => {
        var element = document.createElement('div');
        element.innerHTML = yourHtmlString;
        var imgSrcUrls = element.getElementsByTagName("img");
        for (var i = 0; i < imgSrcUrls.length; i++) {
            var urlValue = imgSrcUrls[i].getAttribute("src");
            if (urlValue) {
                imgSrcUrls[i].setAttribute("class", "editorimage");
            }
        }
    }



    onContentStateChange = contentState => {
        // console.log('as HTML:', draftToHtml(contentState));
        this.setState({ contentState });
    }

    actionCaptured = (action) => {
        let errList = this.state.errors;
        if (action == 'Open') {
            delete errList['capturedText'];

            this.setState({
                dialogCaptured: true,
                dialogCapturedLoading: false,
                dialogCapturedMessage: '',
                dialogCapturedAction: 'Open',
                errors: errList,
                capturedText: '',
                additinal_captured_error: ''
            });
        }
        if (action == 'Close') {
            this.setState({
                dialogCaptured: false,
                dialogCapturedLoading: false,
                dialogCapturedMessage: '',
                dialogCapturedAction: 'Close',

            });
        }
        if (action == 'Save') {
            if (this.state.capturedText != '') {
                api.botbuilder.capturedsave({ 'text': this.state.capturedText, 'workspace_id': this.props.workspace_id }).then(data => {
                    try {
                        if (data.status == false) {

                            this.setState({
                                additinal_captured_error: data.message,
                                dialogCapturedLoading: false,
                                dialogCapturedMessage: ''
                            })
                        } else {
                            this.setState({
                                dialogCaptured: false,
                                dialogCapturedLoading: false,
                                dialogCapturedMessage: '',
                                dialogCapturedAction: 'Save'
                            }, function () {
                                this.props.saveButton(data, 'capturedSave');
                            });
                        }

                    } catch (err) {
                        console.log(err);
                    }
                });
            } else {
                errList['capturedText'] = "This field is required.";
                this.setState({ errors: errList });
            }
        }
        if (action == 'Delete') {
            let msg = '';
            let actionType = '';

            if (this.props.branchDetails.details.capture_as == '') {
                msg = 'Please select a value.';
                actionType = 'DeleteA';
            } else if (this.props.branchDetails.details.capture_as != '') {
                let index = capturedListArr.findIndex(
                    list => list.key === this.props.branchDetails.details.capture_as
                );
                if (index >= 0) {
                    msg = 'This is a default field and cannot be deleted.';
                    actionType = 'DeleteA';
                } else {
                    msg = 'Are you sure you want to delete this captured reply as field? If this field is used in any branch then at that branch captured value will be blank.';
                    actionType = 'Delete';
                }
            }
            this.setState({
                dialogCaptured: true,
                dialogCapturedLoading: false,
                dialogCapturedMessage: msg,
                dialogCapturedAction: actionType
            });
        }
        if (action == 'Deleted') {
            //alert(this.props.branchDetails.details.capture_as)
            api.botbuilder.captureddelete({ 'key': this.props.branchDetails.details.capture_as, 'workspace_id': this.props.workspace_id }).then(data => {
                try {
                    this.setState({
                        dialogCaptured: false,
                        dialogCapturedLoading: false,
                        dialogCapturedMessage: '',
                        dialogCapturedAction: 'Deleted'
                    }, function () {
                        this.props.saveButton(data, 'capturedDelete');
                    });
                } catch (err) {
                    console.log(err);
                }
            });
        }

    }

    checkBranchHasButton = (data, callback) => {
        var check = false;
        if (data.length == 0) {
            check = false;
        } else {
            const result = data.filter(word => word.button_respond_on == 'Branch');
            if (result.length == 0) {
                check = false;
            } else {
                check = true;
            }
        }
        callback(check);

    }

    embedCallback = (data) => {
        // alert(data);
        // return false;
    }

    modalClose = () => {
        this.setState({
            modalstatus: false,
            modaltitle: "",
            modalbody: "",
            modalindex: "",
        });
    };

    modalFooter = (index,callRule) => {
        if (index == 'accept_attachment') {
            return (
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => this.modalClose()}
                        className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                        data-dismiss="modal"
                    >
                        <span>Cancel</span>
                    </button>

                </div>
            );
        }

        else if (index == 'delete_ranscript_rule') {
            return (
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => this.modalClose()}
                        className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                        data-dismiss="modal"
                    >
                        <span>No</span>
                    </button>
                    <button
                        type="button"
                        onClick={() => this.confirmDeleteTranscriptRules()}
                        className="btn linear-gradient yr-submitbtn btn-sm"
                    >
                        <span>Yes</span>
                    </button>
                </div>
            );
        }
        else if (index == 'delete_call_forward') {
            return (
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => this.modalClose()}
                        className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                        data-dismiss="modal"
                    >
                        <span>No</span>
                    </button>
                    <button
                        type="button"
                        onClick={() => this.deleteCallForwardRule(this.state.callRule)}
                        className="btn linear-gradient yr-submitbtn btn-sm"
                    >
                        <span>Yes</span>
                    </button>
                </div>
            );
        }
        else {
            return (
                <div className="modal-footer">
                    <button
                        type="button"
                        onClick={() => this.modalClose()}
                        className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                        data-dismiss="modal"
                    >
                        <span>No</span>
                    </button>
                    <button
                        type="button"
                        onClick={() => this.confirmDeleteRules(this.state.deletemodalid)}
                        className="btn linear-gradient yr-submitbtn btn-sm"
                    >
                        <span>Yes</span>
                    </button>
                </div>
            );
        }
    };



    deleteZipCodeRules = () => {

        this.setState({
            modalstatus: true,
            modaltitle: "Confirm your action",
            modalbody: "Are you sure you want to delete it?",
        }, () => {

            this.props.popupClose()
        })
    }

    confirmDeleteRules = () => {


        api.botbuilder.deleteZipCodeRules({ branch_id: this.props.branchDetails.details.branch_id }).then(data => {
            try {
                this.modalClose();
                let scrolltop = document.querySelector(".addEditBranch").offsetTop - document.body.scrollTop;
                window.scroll({ top: scrolltop, left: 0, behavior: 'smooth' });
                this.props.updateDeleteZipRule(false)
                this.setState({
                    userToaster: true,
                    userToasterMsg: "Zip code rules deleted successfully",
                    userToasterColor: "green",
                    loading: false,
                    errors: []
                }, function () {
                    setTimeout(
                        function () {
                            this.setState({ userToaster: false });
                        }.bind(this),
                        3000
                    );
                });

            } catch (err) {
                console.log(err);
            }
        });



    }

    acceptAttachment = (data) => {

        let val = data.target.checked ? "1" : "0";

        // this.props.branchDetails.branch_buttons.forEach

        if (this.state.branchHasButton == true) {
            this.setState({
                modalstatus: true,
                modalindex: 'accept_attachment',
                modalbody: "Since you have buttons as a response option, you can't accept attachments as a response",
                modaltitle: 'Alert'
            })
        } else {
            let params = {
                branch_id: this.props.branchDetails.details.branch_id,
                val: val
            };
            api.botbuilder.acceptAttchmentResponse(params).then((data) => {
                this.setState({ accept_attachment: val })
                // let branchDetails = this.state.branchDetails;
                // branchDetails.details.live_chat_notification = val;
                // if (data.status == true && val == 1) {
                //   this.setState({ livechatshow: true, branchDetails: branchDetails });
                // } else {
                //   this.setState({ livechatshow: false, branchDetails: branchDetails });
                //   console.log(data.message);
                // }
            });
        }


    }


    deleteTranscriptRule = () => {
        this.setState({
            modalstatus: true,
            modalindex: 'delete_ranscript_rule',
            modalbody: "Are you sure you want to delete?",
            modaltitle: 'Confirm your action'
        })
    }

    confirmDeleteTranscriptRules = () => {
        this.props.saveButton({}, 'delete_transcript_rule');

        this.setState({
            modalstatus: false,
            modalindex: '',
            modalbody: "",
            modaltitle: ''
        })

    }

    // ANKIT START ===========================

    onSelect = (selectedList, selectedItem) => {
        let checkedValueP = this.props.checkedValue;
        // console.log('onselect');
        // console.log(checkedValueP);
        //checkedValueP.push(selectedItem.id);
        checkedValueP.push(selectedItem);
        let errList = this.state.errors;
        delete errList["selectedBkt"];
        this.setState({
            checkedValue: checkedValueP,
            errors: errList,
        }, function () {
            //console.log(this.state.checkedValue)
            this.props.actionTeamMembers(this.state.checkedValue, "checkedValue", '')
        });

    };

    onRemove = (selectedList, removedItem) => {
        let checkedValueP = this.props.checkedValue;
        //checkedValueP.splice(checkedValueP.indexOf(removedItem.id), 1);
        checkedValueP.splice(checkedValueP.indexOf(removedItem), 1);
        // console.log(checkedValueP, 'after remove item');
        this.setState({
            checkedValue: checkedValueP,
        }, function () {
            this.props.actionTeamMembers(this.state.checkedValue, "checkedValue", '')
        });

        if (this.state.checkedValue.length == 0) {
            let errList = this.state.errors;
            errList["selectedBkt"] = "This field is required.";
            this.setState({
                errors: errList,
            });
        }
    };

    deleteCallForwardPopup = (id) => {
        this.setState({
            callRule:id,
            modalstatus: true,
            modalindex: 'delete_call_forward',
            modalbody: "Are you sure you want to delete?",
            modaltitle: 'Confirm your action',
        })
    }

    deleteCallForwardRule = (callRule) => {
        this.setState({
            loading: true,
            modalstatus: false,
        })
        api.botbuilder.deleteCallFarwordNumber({
            id: callRule.id,
            workspace_id: callRule.workspace_id,
            branch_id:callRule.branch_id
        }).then((res) => {
            this.setState({
                loading: false  
            })
            if (res.status) {
                this.props.updateBranchDetailsCall(res.data);
            }
        }).catch((err) => {
            console.log(err);
            this.setState({
                loading: false
            })
        })
    }

    // ANKIT END =============================

    render() {

        const { editorState, contentState, errors } = this.state;


        return (

            <div className="yr-chat-round-box addEditBranch">
                <div className="row">
                    <div className="add-branch-title mb-3 col-md-6">
                        Add/Edit Branch
                    </div>
                    { this.props.bot_type != 'Voice_Turbo' &&
                        <div className={`col-md-6 textright ${(this.props.bot_type == 'GDF' || this.props.bot_type == 'GEN_WEB' || this.props.bot_type == 'GEN_VOICE' ) && 'd-none'}`}>
                        <div>Branch livechat rules</div>
                        <div>
                            <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check" style={{ textAlign: "left" }}>
                                <label>
                                    <input type="checkbox" name="chatPopup" onChange={(e) => this.props.liveChatRules('show', e, '')} checked={(typeof this.props.branchDetails.details != 'undefined' && this.props.branchDetails.details.live_chat_notification == 1) ? 'checked' : ''} />
                                    <span />
                                </label>
                            </span>
                            {typeof this.props.branchDetails.details != 'undefined' && this.props.branchDetails.details.live_chat_notification == 1 &&
                                <a className="cursorpointer viewlivelinks" onClick={(e) => this.props.liveChatRules('view', e, '')}>View</a>
                            }
                        </div>
                    </div>}
                </div>

                {(this.state.loading || this.props.loadingaction) && <div className="ui loading form"></div>}

                <Message
                    color={
                        this.state.userToasterColor
                            ? this.state.userToasterColor
                            : "teal"
                    }
                    style={{
                        display: this.state.userToaster ? "block" : "none",
                        marginTop: 30,
                    }}
                >
                    {this.state.userToasterMsg}
                </Message>

                <Message
                    color={
                        (typeof this.props.toster.color != 'undefined' && this.props.toster.color)
                            ? this.props.toster.color
                            : "teal"
                    }
                    style={{
                        display: (typeof this.props.toster.status != 'undefined' && this.props.toster.status) ? "block" : "none",
                        marginTop: 30,
                    }}
                >
                    {typeof this.props.toster.message != 'undefined' && this.props.toster.message}
                </Message>


                <div className={`${this.props.bot_type == 'GDF' ? 'form-group mb-1 editorImgHidden' : 'form-group mb-1'}`}>
                    <div>
                        <input className={
                            Object.keys(errors).length > 0 && errors.hasOwnProperty('title_error') && typeof errors.title_error[0] != 'undefined'
                                ? "col-form-label col-lg-12 welcome-branch form-control is-invalid focusError"
                                : "col-form-label col-lg-12 welcome-branch form-control"
                        } type="text" onChange={(e) => this.props.textAreaAction(e, 'addtitle', '')} value={typeof this.props.branchDetails.details != 'undefined' ? this.props.branchDetails.details.branch_name : ''}></input>
                        {Object.keys(errors).length > 0 && errors.hasOwnProperty('title_error') && typeof errors.title_error[0] != 'undefined' && (
                            <CustomErrorField
                                message={errors.title_error[0]}
                                className="error invalid-feedback"
                            />
                        )}
                    </div>

                    {this.props.branchDetails.branch_questions && this.props.branchDetails.branch_questions.map((obj2, index) => {
                        return (
                            <div className={
                                Object.keys(errors).length > 0 && errors.hasOwnProperty('question_error') && typeof errors.question_error[index] != 'undefined'
                                    ? "col-lg-12 px-0 focusError"
                                    : "col-lg-12 px-0"
                            } style={{ marginTop: "10px" }} >
                                {/* <textarea className="form-control note-editor"  defaultValue={obj2.question} onChange={(e)=>this.props.textAreaAction(e,'textchange',index)}> </textarea>*/}
                                <Editor
                                    stripPastedStyles={(this.props.bot_type == 'Voice') ? true : false}
                                    editorState={this.state.editor_state[index]}
                                    onEditorStateChange={(e) => this.onEditorStateChange(e, index)}
                                    wrapperClassName="botbuilder-wrapper-class"
                                    editorClassName="botbuilder-editor-class"
                                    toolbarClassName="botbuilder-toolbar-class"

                                    toolbar={(this.props.bot_type == 'WEB' || this.props.bot_type == 'GEN_WEB') ? {
                                        options: ['inline', 'list', 'link', 'emoji', 'image', 'embedded', 'history'],
                                        inline: { inDropdown: false, options: ['bold', 'italic'] },
                                        list: { inDropdown: false, options: ['unordered', 'ordered'], },
                                        link: { inDropdown: false, options: ['link'], },
                                        history: { inDropdown: false },
                                        embedded: {
                                            defaultSize: {
                                                height: 'auto',
                                                width: '250',
                                            },

                                        }
                                    } : this.props.bot_type == "GDF" ? {
                                        options: ['emoji', 'image']
                                    } : {
                                        options: ['emoji', 'history'],
                                        history: { inDropdown: false },
                                    }}
                                />
                                <div className={
                                    Object.keys(errors).length > 0 && errors.hasOwnProperty('question_error') && typeof errors.question_error[index] != 'undefined'
                                        ? "form-control is-invalid"
                                        : "form-control"
                                } style={{ display: "none" }}></div>
                                {Object.keys(errors).length > 0 && errors.hasOwnProperty('question_error') && typeof errors.question_error[index] != 'undefined' && (
                                    <CustomErrorField
                                        message={errors.question_error[index]}
                                        className="error invalid-feedback"
                                    />
                                )}

                                {index > 0 &&
                                    <div className="delBtn" style={{ right: "-25px" }}>
                                        <button type="button" class="btn btn-icon" title="Delete"><i class="la la-trash" onClick={(e) => this.props.textAreaAction(e, 'delete', index)}></i></button>
                                    </div>
                                }
                            </div>
                        )
                    })}
                </div>
                <div className="row">
                    <div className="col-6">
                        {!['GDF','Voice','GEN_VOICE','Voice_Turbo'].includes(this.props.bot_type) && <button className="btn add-link textleft" onClick={() => this.props.openTab('textarea')}>+Add text area</button>}
                        {(this.props.bot_type != 'Voice' && this.props.bot_type != 'GEN_VOICE' && this.props.bot_type != 'Voice_Turbo')&&
                            <button className="btn add-link textleft" onClick={() => this.props.openTab('skills')}>+Add skills</button>
                        }
                        {(this.props.bot_type != 'GDF' && this.props.bot_type != 'Voice' && this.props.bot_type != 'GEN_WEB' && this.props.bot_type != 'GEN_VOICE' && this.props.bot_type != 'Voice_Turbo') && <button className="btn add-link textleft" onClick={() => this.props.openTab('responsequestion')}>+Add response to a question</button>}
                        {(this.props.bot_type == 'WEB') ?
                            <span>
                                <button className="btn add-link text-left" onClick={() => this.props.openTab('links')}>+Add links to start chat from this branch</button>
                                <button className="btn add-link textleft" onClick={() => this.props.openTab('multiplechoice')}>+Add multiple choice question</button>
                                <button className="btn add-link textleft" >Accept attachments as a response
                                    <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check acceptattachemnt" style={{ textAlign: "left" }}>
                                        <label>
                                            <input type="checkbox" name="chatPopup" onChange={(e) => this.acceptAttachment(e)} checked={(typeof this.props.branchDetails.details != 'undefined' && this.state.accept_attachment == 1) ? 'checked' : ''} />
                                            <span />
                                        </label>
                                    </span>

                                </button>

                            </span> : null
                            
                        }


                    </div>
                    <div className="col-6">
                        {
                            (this.props.bot_type == 'WEB' || this.props.bot_type == 'GEN_WEB' || this.props.bot_type == 'Voice' || this.props.bot_type == 'GEN_VOICE') &&
                            <button className="btn add-link pull-right" onClick={() => this.props.openTab('button')} style={{ width: "100%", textAlign: "right" }}>+Add button</button>
                        }
                        {(this.props.bot_type == 'WEB' || this.props.bot_type =='Voice'  ) && <button className="btn add-link pull-right" onClick={() => this.props.openTab('webhook_response')}>+Add zapier rule</button>}
                        {(this.props.bot_type == 'WEB') &&
                            <button className="btn add-link pull-right" onClick={() => this.props.openTab('gtm_trigger_id')} style={{ width: "100%", textAlign: "right" }}>+Add GTM Trigger ID</button>
                        }
                         {
                            (this.props.bot_type == 'Voice' || this.props.bot_type == 'Voice_Turbo') &&
                            <span>
                                <button className="btn add-link pull-right" onClick={() => this.props.openTab('call_forward')} style={{ width: "100%", textAlign: "right" }}>+Add call forwarding rule</button>

                                <button className="btn add-link pull-right" onClick={() => this.props.openTab('branch_call_time')} style={{ width: "100%", textAlign: "right" }}>Start call from this branch during this time</button>
                            </span>
                        }
                    </div>
                    { (this.props.bot_type == 'Voice_Turbo' || this.props.bot_type == 'WEB') && 
                        <div className="col-12">
                            <button className="btn add-link pull-right" onClick={() => this.props.openTab('dataParse')} style={{ width: "100%", textAlign: "right" }}>+Data Parsing list</button>
                        </div>
                    }
                </div>
                {this.props.branchDetails.branch_buttons.length > 0 &&
                    <div className="row buttonlistbtbuilder">
                        <div className="col-12 py-5">
                            <ul style={{ listStyle: "none", paddingLeft: "0px" }} className="yr-btneditable">
                                <ReactSortable
                                    animation={400}
                                    list={this.props.branchDetails.branch_buttons}
                                    setList={(newState) => this.props.saveButton(newState, 'order')}
                                >
                                    {this.props.branchDetails.branch_buttons.length > 0 && this.props.branchDetails.branch_buttons.map((buttonobj, index) => {
                                        return (
                                            <li style={{ display: buttonobj.button_respond_on == 'REVIEW' && this.props.review_links.length == 0 ? 'none' : 'inline' }}>
                                                <button className="btn yr-round-btn mr-3" style={{height:"auto", minHeight:"32px"}}>{buttonobj.button_title} </button>
                                                <div class="yr-btnicon">



                                                    <a onClick={() => this.props.buttonAction('edit', index)} className="cusrsorpointer" tile="Edit"><i class="la la-edit blue"></i></a> <a className="cusrsorpointer" onClick={() => this.props.buttonAction('delete', index)} title="Delete"><i class="la la-trash"></i></a>
                                                </div>
                                            </li>
                                        )
                                    })}
                                </ReactSortable>
                            </ul>
                        </div>
                    </div>

                }


                {/* Transcript Rule */}
                {this.props.branchDetails.details.transscript_rule != '' && this.props.branchDetails.details.transscript_rule != null &&
                    <div className="row mrbottompadd" id="transcriptRules">
                        <div className="col-12 mt-10">
                            <p class="responsing_heading"><strong>Transcript Rule</strong>
                                <a title="Edit" className="cursorpointer" style={{ marginLeft: "30px" }} onClick={() => this.props.openTab('transcriptrule')}><i className="la la-edit blue" /></a>
                                <a title="Delete" onClick={() => this.deleteTranscriptRule()} className="cursorpointer"><i className="la la-trash" /></a>
                            </p></div>

                        <div className="col-12 mt-10">
                            {this.props.branchDetails.details.trans_script_rule == 1 ? 'Automatically send all users a transcript email.' : 'Button will appear on the chat where user can click to receive email transcript.'}
                        </div>
                        {this.props.branchDetails.details.trans_script_rule_button != '' && this.props.branchDetails.details.trans_script_rule_button != null &&
                            <button className="btn yr-round-btn mr-3" style={{ marginTop: "15px" }}>{this.props.branchDetails.details.trans_script_rule_button}</button>
                        }
                    </div>
                }
                {/* Transcript Rule */}


                {this.props.branchDetails.ai_list &&
                    <div className="row mrbottompadd" id="airules">
                        <div className="col-12 mt-10">
                            {this.props.branchDetails.ai_list && this.props.branchDetails.ai_list.map((obj, index) => {

                                let text = "";
                                if (obj.intent_name != '' && obj.intent_name != null) {
                                    text = obj.intent_name;
                                }
                                if (obj.subcategory_name != '' && obj.subcategory_name != null) {
                                    text += "(" + obj.subcategory_name + ")";
                                }
                                if (obj.category_name != '' && obj.category_name != null) {
                                    text += "(" + obj.category_name + ")";
                                }

                                return (
                                    <div class="row">
                                        <div className="col-md-10">If AI recognizes {text}  Go to Branch {obj.branch_name}</div>
                                        <div className="col-md-2"><a className="cusrsorpointer" title="Edit" onClick={() => this.props.skillsAction('edit', index, obj.id)}><i className="la la-edit blue" /></a> <a className="cusrsorpointer" title="Delete" onClick={() => this.props.skillsAction('delete', index, obj.id)}><i className="la la-trash" /></a>

                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                }

                {typeof this.props.branchDetails.multiple_choice_options != 'undefined' && this.props.branchDetails.multiple_choice_options.confirmation_button != '' &&
                    <div className="row mrbottompadd" id="multiplecholice">
                        <div className="col-12 mt-10">
                            <p class="responsing_heading"><strong>Multiple Choice Options</strong> <a title="Edit" className="cursorpointer" onClick={() => this.props.openTab('multiplechoice')}><i className="la la-edit blue" /></a> <a title="Delete" onClick={() => this.props.MultipleChoiceAction('delete', '')} className="cursorpointer"><i className="la la-trash" /></a>
                            </p></div>

                        <div className="col-12 mt-10">
                            {typeof this.props.branchDetails.multiple_choice_options != 'undefined' && Object.keys(this.props.branchDetails.multiple_choice_options.option).length > 0 && Object.keys(this.props.branchDetails.multiple_choice_options.option).map((obj2, index) => {
                                return (
                                    <div>{this.props.branchDetails.multiple_choice_options.option[obj2]}</div>
                                )
                            })}

                        </div>
                        <button className="btn yr-round-btn mr-3">{this.props.branchDetails.multiple_choice_options.confirmation_button}</button>
                    </div>
                }
                {this.props.branchDetails.question_response_list && this.props.branchDetails.question_response_list.length > 0 &&
                    <div className="row mrbottompadd" id="responsetoquestion">
                        <div className="col-12 mt-10">
                            <p class="responsing_heading"><strong>Response if question is detected</strong>

                            </p></div>

                        <div className="col-12 mt-10">
                            {this.props.branchDetails.question_response_list && this.props.branchDetails.question_response_list.map((obj, index) => {
                                return (
                                    <div class="row">
                                        <div className="col-md-10">{obj.question}</div>
                                        <div className="col-md-2"><a className="cursorpointer" onClick={() => this.props.actionQuestionResponse('edit', { question_id: obj.id }, index)} title="Edit"><i className="la la-edit blue" /></a> <a title="Delete" className="cursorpointer" onClick={() => this.props.actionQuestionResponse('delete', { question_id: obj.id }, index)}><i className="la la-trash" /></a></div>
                                    </div>
                                )
                            })}

                        </div>

                    </div>
                }
                {this.props.branchDetails.callForwardRule && this.props.branchDetails.callForwardRule.length > 0 && 
                    <div className="row mrbottompadd">
                        <div className="col-12 mt-10">
                            {this.props.bot_type == 'Voice' ? this.props.branchDetails.callForwardRule.map((callRule) => (
                                <p class="responsing_heading">
                                    <strong>If someone presses <i style={{fontWeight:"bold"}}>{callRule.key_digit}</i> at any time during the call, the call will be forwarded to <i>{callRule.country_code}{callRule.phone_number}</i></strong>
                                    <a className="cursorpointer" style={{marginLeft: 10}} onClick={() => this.props.openTab('call_forward')} title="Edit"><i className="la la-edit blue" /></a>
                                    <a  title="Delete" className="cursorpointer" onClick={() => this.deleteCallForwardPopup(callRule)}><i className="la la-trash" /></a>
                                </p>
                            )) : this.props.branchDetails.callForwardRule.map((callRule) => (
                                <p class="responsing_heading">
                                    <strong>If someone asks to speak to a human or representative, forward the call to <i>{callRule.country_code}{callRule.phone_number}</i></strong>
                                    {(this.props.branchDetails.branch_id == callRule.branch_id || callRule.branch_id == null || callRule.branch_id == '0' ) ?
                                        <>
                                       
                                           <a className="cursorpointer" style={{marginLeft: 10}} onClick={() => this.props.openTab('call_forward')} title="Edit"><i className="la la-edit blue" /></a>
                                           <a  title="Delete" className="cursorpointer" onClick={() => this.deleteCallForwardPopup(callRule)}><i className="la la-trash" /></a>
                                        </> : <>
                                        <div>This rule is associated with <strong>{this.getBranchNameById(callRule.branch_id)}</strong></div>
                                        </>
                                    }
                                </p>
                            ))}
                            
                        </div>
                    </div>
                }
                {/* webhooks response */}
                {this.props.branchDetails.zapier_webhooks  && this.props.branchDetails.zapier_webhooks.length > 0 &&
                    <div className="row mrbottompadd" id="responsetoquestion">
                        <div className="col-12 mt-10">
                            <p class="responsing_heading"><strong>If a conversation goes through this branch, the chat will be sent to Zapier.</strong>

                            </p></div>

                        <div className="col-12 mt-10">
                            {this.props.branchDetails.zapier_webhooks && this.props.branchDetails.zapier_webhooks.map((obj, index) => {
                                let brnach=this.props.branchList.findIndex((it)=>{
                                    return it.branch_id==obj.branch
                                });
                                // console.log("poo",this.props.branchList[brnach].branch_name,brnach)
                                return (
                                    <div class="row webhhokdiv">
                                        <div className="col-md-10" style={{wordWrap: 'break-word'}}>{this.props.branchList[brnach].branch_name} <b style={{marginLeft:'2px'}}>({this.props.branchList[brnach].branch_id})</b></div>
                                        <div className="col-md-2"><a className="cursorpointer" onClick={() => this.props.actionwebhookapi('edit', { webhook_id: obj.id }, index)} title="Edit"><i className="la la-edit blue" /></a> 
                                        <a title="Delete" className="cursorpointer" onClick={() => this.deletezapBranch('delete', { webhook_id: obj.id,index:index }, index)}><i className="la la-trash" /></a></div>
                                    </div>
                                )
                            })}

                        </div>

                    </div>
                }

                {/* end webhook */}
                {this.props.branchDetails.workspace_branch_url && this.props.branchDetails.workspace_branch_url.length > 0 &&
                    <div className="row mrbottompadd" id="linkisdetected">
                        <div className="col-12 mt-10">
                            <p class="responsing_heading"><strong>If the following links are detected, the conversation will start from this branch</strong> <a title="Edit" className="cursorpointer" onClick={() => this.props.openTab('links')}><i className="la la-edit blue" /></a>
                                <span class="yr-aiEditBtn actionclass" style={{ marginLeft: "5px" }}><i title="Delete" class="fa fa-trash" onclick="deleteMultipleChoiceAll('2888')"></i></span>
                            </p></div>
                        <div className="col-12 mt-10">
                            {this.props.branchDetails.workspace_branch_url && this.props.branchDetails.workspace_branch_url.map((obj, index) => {
                                return (
                                    <div class="row">
                                        <div className="col-md-10 breakword">{obj.url}</div>
                                        <div className="col-md-2"> <a title="Delete" className="cursorpointer" onClick={() => this.props.linkAction('delete', { branch_link_id: obj.id }, index)}><i className="la la-trash" /></a></div>
                                    </div>
                                )
                            })}
                        </div>

                    </div>
                }
                {(this.props.bot_type != 'Voice' && this.props.bot_type != 'GEN_VOICE' ) &&
                <div className="row">

                    <div className="col-md-6">
                        {
                            (this.props.bot_type != 'GDF' && this.props.bot_type != 'Voice_Turbo'  )&&
                            <div className="form-group">
                                <label>Validation on reply:</label>
                                <select className="form-control" value={this.props.branchDetails.details.validation} onChange={(e) => this.props.saveButton(e, 'validation')}>
                                    <option value="">Select</option>
                                    <option value="phonenumber">Phone No</option>
                                    <option value="email">Email Id</option>
                                    <option value="zipcode">Zip Code</option>
                                </select>
                                {this.props.branchDetails.details.validation == 'zipcode' && this.props.branchDetails.details.capture_as == 'zipcode' &&
                                    <div>
                                        <button className="btn add-link textleft inlineelement" onClick={() => this.props.openTab('zipcoderules')}>+Add zip code rules</button>
                                        {this.props.checkzipcaptured &&
                                            <a title="Delete" className="btn btn-icon gray inlineelement pull-right" onClick={() => this.deleteZipCodeRules('Delete')}><i className="la la-trash"></i></a>
                                        }
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    {this.props.bot_type != 'Voice_Turbo'  &&
                    <div className="col-md-6">
                        <div className="form-group mb-0">
                            <label>Capture reply as:</label>
                            <select className="form-control" onChange={(e) => this.props.saveButton(e, 'captured_as')} value={this.props.branchDetails.details.capture_as}>
                                <option value=''>Select</option>
                                {capturedListArr && capturedListArr.map((obj, index) => {
                                    return (
                                        <option value={obj.key}>{obj.val}</option>
                                    )
                                })}
                                {this.props.captured_list && this.props.captured_list.map((buttonobj, index) => {
                                    return (
                                        <option value={buttonobj.slug}>{buttonobj.title}</option>
                                    )
                                })}

                            </select>
                        </div>
                        <div className="row">
                            {this.props.bot_type != "GDF" && <div className="col-12 d-flex justify-content-between">
                                <button className="btn add-link" onClick={() => this.actionCaptured('Open')}>+Add New</button>
                                <a title="Delete" className="btn btn-icon gray" onClick={() => this.actionCaptured('Delete')}><i className="la la-trash"></i></a>
                            </div>}
                            {this.props.branchDetails.details.capture_as == 'phonenumber' &&
                                <div class="showCountryCode d-flex col-12">
                                    <span class="countrycodeshow">Show country code</span>
                                    <span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check" style={{ textAlign: "left" }}>
                                        <label>
                                            <input type="checkbox" name="chatPopup" onChange={(e) => this.props.saveButton(e, 'show_country_code')} checked={(typeof this.props.branchDetails.details != 'undefined' && this.props.branchDetails.details.show_country_code == 1) ? 'checked' : ''} />
                                            <span />
                                        </label>
                                    </span>
                                </div>
                            }
                        </div>
                    </div>}
                </div>
    }
                {/* </div> */}
                {this.props.branchDetails.details.validation == 'phonenumber' &&
                    <div>
                        <div className="col-lg-12 px-0" style={{ marginTop: '10px' }}>
                            <textarea className="form-control note-editor" name='phone_length_reply' onChange={(e) => this.props.saveButton(e, 'validationreply')} placeholder="Reply for phone length" value={(this.props.branchDetails.details.validation_reply != '' && this.props.branchDetails.details.validation_reply != null && this.props.branchDetails.details.validation_reply.length > 0) ? this.props.branchDetails.details.validation_reply[0]?.phone_length_reply : ''}></textarea>
                        </div>
                        <div className="col-lg-12 px-0" style={{ marginTop: '10px' }}>
                            <textarea className="form-control note-editor" name='invalid_phone_reply' onChange={(e) => this.props.saveButton(e, 'validationreply')} placeholder="Reply for invalid phone " value={(this.props.branchDetails.details.validation_reply != '' && this.props.branchDetails.details.validation_reply != null && this.props.branchDetails.details.validation_reply.length > 0) ? this.props.branchDetails.details.validation_reply[1]?.invalid_phone_reply : ''}></textarea>
                        </div>
                    </div>
                }

                {this.props.branchDetails.details.validation == 'email' &&
                    <div className="col-lg-12 px-0" style={{ marginTop: '10px' }}>
                        <textarea className="form-control note-editor" name='email_reply' onChange={(e) => this.props.saveButton(e, 'validationreply')} placeholder="Email validation reply" value={(this.props.branchDetails.details.validation_reply != '' && this.props.branchDetails.details.validation_reply != null && this.props.branchDetails.details.validation_reply.length > 0) ? this.props.branchDetails.details.validation_reply[0].email_reply : ''}></textarea>
                    </div>
                }

                {(this.state.branchHasButton == false ) &&
                    <div className="row mt-40" >
                        <div className="col-md-6 ">
                            <label   >if no AI is analyzed, Go to branch </label>
                        </div>
                        <div className="col-md-6 ">
                            {
                                (this.props.bot_type == 'GDF'  ) ?
                                    <select className="form-control" onChange={(e) => this.props.saveButton(e, 'noAIselected')} value={this.props.branchDetails.details.target_branch}>
                                        <option > Select</option>
                                        {this.props.branchList[0].trainyourAI ? <option value={this.props.branchList[0].branch_id} optiontext="1"> Context Branch</option>:<option value={this.props.branchList[0].branch_id} optiontext="1"> Confused Branch and then repeat the current branch's question</option>}
                                        {this.props.branchList && this.props.branchList.map((value, index) => {
                                            return (index > 1) ? <option value={value.branch_id} >{value.branch_name}</option> : ''
                                        })}
                                    </select>
                                    :  (this.props.bot_type == 'GEN_WEB' ||  this.props.bot_type == 'GEN_VOICE' ) ? <select className="form-control" onChange={(e) => this.props.saveButton(e, 'noAIselected')} value={this.props.branchDetails.details.target_branch}>
                                    <option > Select</option>
                                    { this.props.branchList.map((value, index) => {
                                        return  <option value={value.branch_id} >{value.branch_name}</option> 
                                    })}
                                </select> :   (this.props.bot_type == 'Voice_Turbo'  ) ?
                                    <select className="form-control" onChange={(e) => this.props.saveButton(e, 'noAIselected')} value={this.props.branchDetails.details.target_branch}>
                                        <option value="">Select</option>
                                        {this.props.branchList && this.props.branchList.map((value, index) => {
                                            if (value.trainyourAI  == 1) {
                                                return <option value={value.branch_id} >{value.branch_name}</option>
                                            }
                                        })}
                                    </select> : 
                                    <select className="form-control" onChange={(e) => this.props.saveButton(e, 'noAIselected')} value={this.props.branchDetails.details.target_branch}>
                                        <option value="">Select</option>
                                        {this.props.branchList[0].trainyourAI ? 
                                            <option value={this.props.branchList[0].branch_id} optiontext="1">{this.props.branchList[0].branch_name}</option>
                                            :
                                            <>
                                                <option value={this.props.branchList[0].branch_id} optiontext="1">{this.props.branchList[0].branch_name} and then repeat the current branch's question</option>
                                            <option value={this.props.branchList[0].branch_id} optiontext="2">{this.props.branchList[0].branch_name} and use  rules on that branch</option>
                                        </>
                                        }
                                        
                                        {this.props.branchList && this.props.branchList.map((value, index) => {
                                            if (index > 0) {
                                                return <option value={value.branch_id} >{value.branch_name}</option>
                                            }
                                        })}
                                    </select>

                            }
                        </div>
                    </div>

                }
                {
                this.props.bot_type != 'Voice_Turbo' &&
                <div className="chatCompleted" style={{ marginTop: "2em" }}>

                    <div className="row" >
                        <div className="col-md-10 ">
                            <label   >Conversation has completed</label>
                        </div>
                        <div className="col-md-2 ">
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <label className="kt-checkbox kt-checkbox--single chat-checkbox">
                                        <input type="checkbox" checked={this.props.branchDetails.details.is_end == 'Y' ? 'true' : false} onChange={(e) => this.props.saveButton(e, 'is_end')} />
                                        <span />
                                    </label>
                                </span>
                            </div>
                        </div>
                    </div>


                    {this.props.branchDetails.details.is_end && this.props.branchDetails.details.is_end == 'Y' &&
                        <div>
                            <div className="row">
                                <div className="col-md-10 ">
                                    <label  >Send to team member based on profile/manage team rules</label>
                                </div>
                                <div className="col-md-2 ">
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <label className="kt-checkbox kt-checkbox--single chat-checkbox">
                                                <input type="checkbox" checked={this.props.branchDetails.details.team_profile == '1' ? true : false} onChange={(e) => this.props.saveButton(e, 'team_profile')} />
                                                <span />
                                            </label>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-10 ">
                                    <label  >Please provide and email and/or SMS number to send to when a conversation ends on this branch</label>
                                </div>
                                <div className="col-md-2 ">
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            <label className="kt-checkbox kt-checkbox--single chat-checkbox">
                                                <input type="checkbox" checked={this.props.branchDetails.details.team_workspace_members == '1' ? true : false} onChange={(e) => this.props.saveButton(e, 'team_workspace_members')} />
                                                <span />
                                            </label>
                                        </span>
                                    </div>
                                </div>
                            </div></div>
                    }
                    {this.props.branchDetails.details.is_end && this.props.branchDetails.details.is_end == 'Y' && this.props.branchDetails.details.team_workspace_members == '1' &&
                        <div>
                            {this.props.branchDetails.team_data.map((obj, index) => {
                                return (
                                    <div className={
                                        Object.keys(errors).length > 0 && obj.email != '' && (errors.hasOwnProperty('email_phone_error') || errors.hasOwnProperty('email_error') || errors.hasOwnProperty('phone_error'))
                                            ? "focusError row"
                                            : "row"}>
                                        <div className="col-md-6">
                                            <div className="form-group" style={{ marginBottom: 10 }}>
                                                <input type="email" className={
                                                    Object.keys(errors).length > 0 && errors.hasOwnProperty('email_error') && typeof errors.email_error[index] != 'undefined'
                                                        ? "form-control is-invalid focusError"
                                                        : "form-control"
                                                } placeholder="Email" value={obj.email} onChange={(e) => this.props.actionTeamMembers(e, 'email_change', index)} />
                                                {Object.keys(errors).length > 0 && errors.hasOwnProperty('email_error') && typeof errors.email_error[index] != 'undefined' && (
                                                    <CustomErrorField
                                                        message={errors.email_error[index]}
                                                        className="error invalid-feedback"
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group" style={{ marginBottom: 10 }} value={obj.country_code} >
                                                {/* <select className="form-control" onChange={(e) => this.props.actionTeamMembers(e, 'isd_code_change', index)}>
                                                    <option value="">USA(+1)</option>
                                                    <option value="">India (+91)</option>
                                                </select> */}

                                                <select className="form-control" onChange={(e) => this.props.actionTeamMembers(e, 'isd_code_change', index)} value={obj.country_code != '' ? obj.country_code : '+1'}>
                                                    {this.props.defaultCountryCodeLists && this.props.defaultCountryCodeLists.map((value, index) => {
                                                        return (
                                                            <option value={value.country_code}>{value.country_name}</option>

                                                        )
                                                    })}

                                                    <optgroup label="Other countries">

                                                        {this.props.countryCodeLists && this.props.countryCodeLists.map((value, index) => {
                                                            return (
                                                                <option value={value.country_code}>{value.country_name}</option>

                                                            )
                                                        })} </optgroup>
                                                </select>


                                            </div>



                                            <div className="form-group" style={{ marginBottom: 10 }}>
                                                <input type="text" className={
                                                    Object.keys(errors).length > 0 && obj.phone != '' && errors.hasOwnProperty('phone_error') && typeof errors.phone_error[index] != 'undefined'
                                                        ? "form-control is-invalid focusError"
                                                        : "form-control focusError"} placeholder="Phone Number" value={obj.phone} onChange={(e) => this.props.actionTeamMembers(e, 'phone_change', index)} />
                                                {Object.keys(errors).length > 0 && errors.hasOwnProperty('phone_error') && typeof errors.phone_error[index] != 'undefined' && (
                                                    <CustomErrorField
                                                        message={errors.phone_error[index]}
                                                        className="error invalid-feedback"
                                                    />
                                                )}
                                            </div>
                                            {index > 0 &&
                                                <div className="delBtn" style={{ right: "-14px" }}>
                                                    <button type="button" class="btn btn-icon" title="Delete"><i class="la la-trash" onClick={(e) => this.props.actionTeamMembers(e, 'delete', index)}></i></button>
                                                </div>
                                            }
                                        </div>
                                        <div className={
                                            Object.keys(errors).length > 0 && errors.hasOwnProperty('email_phone_error') && typeof errors.email_phone_error[index] != 'undefined'
                                                ? "form-control is-invalid focusError"
                                                : "form-control focusError"} style={{ display: "none" }}></div>
                                        {Object.keys(errors).length > 0 && errors.hasOwnProperty('email_phone_error') && typeof errors.email_phone_error[index] != 'undefined' && (
                                            <CustomErrorField
                                                message={errors.email_phone_error[index]}
                                                className="error invalid-feedback "
                                            />
                                        )}
                                    </div>)

                            })}

                            <a className="add-gradient form-group" onClick={(e) => this.props.actionTeamMembers(e, 'add', '')}>+Add</a>
                        </div>
                    }
                    {this.props.branchDetails.details.is_end && this.props.branchDetails.details.is_end == 'Y' &&
                        <a className="add-gradient form-group" onClick={() => this.props.openTab('transcriptrule')}>+Add transcript rule</a>
                    }

                    {!(this.props.bot_type === "GDF") && this.props.branchDetails.details.is_end &&
                        this.props.branchDetails.details.is_end == "Y" && (
                            <div>
                                <div className="row">
                                    <div className="col-md-10 ">
                                        <label>Automatically add users who complete the chat from this branch into a bucket</label>
                                    </div>
                                    <div className="col-md-2 ">
                                        <div className="input-group-append">
                                            <span className="input-group-text">
                                                <label className="kt-checkbox kt-checkbox--single chat-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        checked={
                                                            this.props.branchDetails.details.select_contact ==
                                                                "1"
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(e) =>
                                                            this.props.saveButton(e, "select_contact")
                                                        }
                                                    />
                                                    <span />
                                                </label>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    {this.props.branchDetails.details.is_end &&
                        this.props.branchDetails.details.is_end == "Y" &&
                        this.props.branchDetails.details.select_contact == "1" && (
                            <div>
                                <div className="row">
                                    <div className="col-md-10">
                                        <div className="form-group mb-0">
                                            <label>Choose bucket:</label>
                                            <Multiselect
                                                options={this.state.contact_bucket} // Options to display in the dropdown
                                                selectedValues={this.props.checkedValue} // Preselected value to persist in dropdown
                                                onSelect={this.onSelect} // Function will trigger on select event
                                                onRemove={this.onRemove} // Function will trigger on remove event
                                                displayValue="name" // Property name to display in the dropdown options
                                                closeIcon="circle"
                                                avoidHighlightFirstOption={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                </div>
                }

                <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                        <button type="submit" className="btn linear-gradient yr-submitbtn mt-4" onClick={() => this.saveBranch()}>Save Branch</button>
                    </div>
                </div>

                <div
                    className={this.state.dialogCaptured ? "modal fade show" : "modal fade"}
                    data-backdrop="static"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="staticBackdrop"
                    aria-hidden="true"
                    style={{ display: this.state.dialogCaptured ? "block" : "none" }}
                >
                    <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 600 }}>
                        {this.state.dialogCapturedLoading && <div className="ui loading form"></div>}

                        <div className="modal-content">
                            <button
                                type="button"
                                className="close linear-gradient"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => this.actionCaptured('Close')}
                            >
                                X
                            </button>
                            <div className="modal-header">
                                <h5 className="modal-title">{this.state.dialogCapturedAction == 'Delete' ? 'Confirm your action' : this.state.dialogCapturedAction == 'DeleteA' ? 'Alert' : 'Additional Fields'}</h5>
                            </div>

                            <div className="modal-body">
                                <div className="px-4">
                                    {this.state.dialogCapturedAction == 'Delete' || this.state.dialogCapturedAction == 'DeleteA' ?
                                        <p dangerouslySetInnerHTML={{ __html: this.state.dialogCapturedMessage }}></p>
                                        :
                                        <div class="form-group row">
                                            <label class="col-lg-4 col-form-label" style={{ color: '#848588' }}>Field :</label>
                                            <div class="col-lg-8">
                                                <div class="field">
                                                    <div class="ui fluid input">
                                                        <input autocomplete="off"
                                                            name="capturedText"
                                                            placeholder=""
                                                            type="text"
                                                            value={this.state.capturedText}
                                                            onChange={(e) => {
                                                                let errList = this.state.errors;
                                                                if (e.target.value != '')
                                                                    delete errList[e.target.name];
                                                                else
                                                                    errList[e.target.name] = "This field is required.";
                                                                this.setState({ capturedText: e.target.value, errors: errList })
                                                            }}
                                                        />
                                                    </div>
                                                    {errors && errors.hasOwnProperty('capturedText') && (
                                                        <div className="red">{errors['capturedText']}</div>
                                                    )}

                                                    {this.state.additinal_captured_error != '' && <div className="red">{this.state.additinal_captured_error}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {this.state.dialogCapturedAction == 'DeleteA' && (
                                <div className="modal-footer">
                                    <button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" onClick={() => this.actionCaptured('Close')} data-dismiss="modal">
                                        <span>Ok</span>
                                    </button>
                                </div>
                            )}
                            {this.state.dialogCapturedAction == 'Delete' && (
                                <div className="modal-footer">
                                    <button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" onClick={() => this.actionCaptured('Close')} data-dismiss="modal">
                                        <span>No</span>
                                    </button>
                                    <button type="button" className="btn linear-gradient yr-submitbtn btn-sm" onClick={() => this.actionCaptured('Deleted')}>
                                        Yes
                                    </button>
                                </div>
                            )}
                            {this.state.dialogCapturedAction == 'Open' && (
                                <div className="modal-footer">
                                    <button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" onClick={() => this.actionCaptured('Close')} data-dismiss="modal">
                                        <span>Close</span>
                                    </button>
                                    <button type="button" className="btn linear-gradient yr-submitbtn btn-sm" onClick={() => this.actionCaptured('Save')}>
                                        Save
                                    </button>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.modalstatus}
                    footer={this.modalFooter(this.state.modalindex)}
                    onclose={this.modalClose}
                    title={this.state.modaltitle}
                    body={this.state.modalbody}
                />

            </div>
        )
    }
}


export default BranchDetail;
