import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Segment, Table, Pagination } from "semantic-ui-react";
import "../../Grid/grid.css";
import "../../Modal/dialog.css";
import { validNewPassword } from '../../../validation/Validation';
import api from "../../../api";

const CustomModel = (props) => {
    const [loading, setloading] = useState(false);
    const [formdata, setformdata] = useState([]);
    const [searchdata, setsearchdata] = useState([]);
    const [errs, seterrs] = useState([]);
    const [msg, setmsg] = useState("");
    const [formerrs, setformerrs] = useState({});
    const [formlerrs, setformlerrs] = useState({});
    const [searcherrs, setsearcherrs] = useState("");
    const  [key, setkey] = useState(Math.round(Math.random() * 100));
    useEffect(() => {
        if(typeof props?.exist_data!="undefined" && typeof props?.exist_data?.parsed_data!="undefined"){
            setformdata(props.exist_data?.parsed_data);
            setkey(Math.random()*100); 
        }else{
            setformdata(props.parsed_data);
        }
        if(typeof props?.exist_data!="undefined" && typeof props?.exist_data?.search_data!="undefined"){
            let sset= props?.exist_data?.search_data ? props?.exist_data?.search_data:[];
            setsearchdata(sset);
        }
        
        setkey(Math.random()*100); 
    }, [props]);

   

   const  showOptions=(data)=>{
     if(data.length > 0 && typeof data == "object"){
        console.log("dataa",data,typeof data)
        return(<>
        {data.map((item,index) => (<option key={item?.data_id} value={item?.data_id}>{item?.data_name}&nbsp;&nbsp;({item?.data_id})</option>))}
        </>);
     }else{
       return(<p>No data</p>);
     }

    }
//    const changeKey=useCallback((e)=>{
//     e.preventDefault();
//       let name=e.target.name;
//       let val=e.target.value;
//     //   let formData=formdata;
//     //   let ind=formData.findIndex(item=>item?.key==name);
//     //    formData[ind].label=val;
//       setformdata(prevdata=>prevdata.map(item=>item?.key==name ? {...item,label:val} : item)); 
//       setkey(Math.random()*100);  
        
//         // setTimeout(()=>{
//         //     document.getElementById(name).focus();
//         //   },100);

//    },[]);

//    const changeVal=useCallback((e,keyid)=>{
//     e.preventDefault();
//       let val=e.target.value;
//     //   let formData=formdata;
//     //   let ind=formData.findIndex(item=>item?.key==keyid);
//     //    formData[ind].value=val;
//       setformdata(prevdata=>prevdata.map(item=>item.key==keyid ? {...item,value:val}: item)); 
//       setkey(Math.random()*100); 
//     //   setTimeout(()=>{
//     //     document.getElementById(keyid+"_value").focus();
//     //   },100);
       
//    },[]);
   
   const closeCustomPopup=()=>{
    seterrs([]);
    setformerrs({});
    setformlerrs({});
    setsearcherrs("");
     props.modalClose();
   }
   const validateForm=()=>{
    let err=[];
    let err1={};
    let err2={};
    let iserr=false;
      if(formdata.length==0){
         iserr=true;
         err.push("No parsed found.");
         seterrs(err);
      }else{
        for(let i=0;i<formdata.length;i++){
            // if(formdata[i].label==null){
            //     iserr=true;
            //     err2[formdata[i].key]=`Label cannot be blank or null.`;
            // }else if(formdata[i].label.trim().length==""){
            //     iserr=true;
            //     err2[formdata[i].key]=`Label cannot be blank or null.`;
            // }
            if(formdata[i].value==null){
                let v=`This field is required.`;
                iserr=true;
                err1[formdata[i].key]=v;
            }
            else if((formdata[i].value && formdata[i].value.trim().length==0) || !formdata[i].value){
                let v=`This field is required.`;
                iserr=true;
                err1[formdata[i].key]=v;
            }
        }
        
        if(Object.keys(err1).length>0){
            setformerrs(err1);
        }
        if(Object.keys(err2).length>0){
            setformlerrs(err2);
        }

        
      }
      console.log("search",searchdata);
      if(searchdata.length>0 ){
       
        for(let i=0;i<searchdata.length;i++){
            if(searchdata[i].val==null){
                iserr=true;
                setsearcherrs("Please select value for every search criteria.");
              }else if(searchdata[i].val=="" || searchdata[i].val=="Select value for business units"){
                iserr=true;
                setsearcherrs("Please select value for every search criteria.");
          }
        }
      }
      setTimeout(() => {
        seterrs([]);
        setformerrs({});
        setformlerrs({});
        setsearcherrs("");
       
      }, 4000);
      return iserr;
   }
   const handleSubmit=(e)=>{
  
    let iserr= validateForm();
    if(formdata.length!=0 && searchdata.length!=0 && !iserr){
        setloading(true);
        api.chatlist.saveManulPushdata({parsed_data:formdata,search_data:searchdata,logid:props.curid,module:props.module}).then((data) => {
            if((data.status)){
                setloading(false);
                setmsg("Data saved successfully.");
                window.scrollTo(0, 0);
                setTimeout(() => {
                    closeCustomPopup();
                }, 5000);
               
            }
           
        }).catch(err=>{
            setloading(false);
        });
    }else{
      //seterrs(["All fields are required."]);
      setloading(false);
      setTimeout(() => {
        seterrs([]);
      }, 4000);
    }    
    setTimeout(() => {
        setmsg("");
      }, 4000);
       
   }
   const changeSeachVal=(e,itemdata)=>{
       let name=e.target.name;
       let val=e.target.value;
       let search_data=searchdata ? searchdata :[];
       let ind=search_data.findIndex(item=>item?.name==name);
       val = val==val.toLowerCase().includes("select value") ? "":val;
       let label="";
       if(val!==""){
           itemdata=JSON.parse(itemdata.response_data); 
          let sr=itemdata.filter((t)=>t.data_id==val);
          label=typeof sr[0]?.data_name !="undefined" ? sr[0]?.data_name:"";
       }else{
        label="";
       }
       if(ind==-1){
        search_data.push({name:name,val:val,label:label});
       }else{
         search_data[ind].val=val;
         search_data[ind].label=label;
       }
       setsearchdata(search_data);
       setkey(Math.random()*100); 

   }
    return (
        <React.Fragment>
        {/* {<div style={{ display: loading }} class="ui loading form mh-loading-custom"></div>} */}
        <div
            id="notificationSetingModalPopup"
            className={props.show ? "modal fade show" : "modal fade"}
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="staticBackdrop"
            aria-hidden="true"
            style={{ display: props.show ? "block" : "none" }}
        >
            <div className="modal-dialog modal-dialog-centered"  role="document" style={{width:"50%",minWidth:'40%'}}>
                <div className="modal-content">
                    <button
                        type="button"
                        className="close linear-gradient"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => props.modalClose()}
                    >
                        X
                </button>
                    <div className="modal-header" style={{marginBottom:10}}>
                        <h5 className="modal-title">{props.title}</h5>
                    </div>

                    {loading && <div className="ui loading form"></div>}
                    {errs.length > 0 && <div className='alert alert-danger'>{errs[0]}</div>}
                    {msg.length > 0 && <div className='alert alert-success'>{msg}</div>}
                    <div className="modal-body" style={{margin:"20px"}}>
                        <form style={{maxHeight:'55vh',overflowY:'auto',overflowX:'hidden',padding:'5px'}}>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <h6>Parsed data</h6>
                                        {formdata.length  ? 
                            <Table id="additional_captured">
                                <Table.Body key={key}>
                                    {formdata.map(function(item,index){
                                        let vall=(typeof item['value'] == 'string') ? item['value'] : JSON.stringify(item['value']);
                                        return (
                                            <Table.Row style={{wordBreak:'break-all'}} key={index}>
                                                <Table.Cell>
                                                    {/* <input key={index} id={item.key} className='form-control' onChange={(e) => {
                                                    e.preventDefault();
                                                    const newArr = [...formdata];
                                                    newArr[index].label = e.target.value;
                                                    setformdata(newArr);
                                                    }} type="text" 
                                                    name={item['key']} 
                                                    defaultValue ={item.label} /> */}
                                                    <strong>{item.label}</strong>
                                                
                                                {/* {Object.keys(formlerrs).length>0 && typeof formlerrs[item.key]!="undefined" && <p className='text-danger'>{formlerrs[item.key]}</p> } */}
                                                </Table.Cell>
                                                <Table.Cell><input key={index} className='form-control'  type="text" id={`${item['key']}_value`} onChange={(e) => {
                                                    e.preventDefault();
                                                    const newArr = [...formdata];
                                                    newArr[index].value = e.target.value;
                                                    setformdata(newArr);
                                                    }} 
                                                    name={`${item['key']}_value`}
                                                    defaultValue ={vall} />
                                                {Object.keys(formerrs).length>0 && typeof formerrs[item.key]!="undefined" && <p className='text-danger'>{formerrs[item.key]}</p> }
                                                </Table.Cell>
                                            </Table.Row>
                                            )
                                        }) 
                                    }
                                </Table.Body>
                            </Table> : <>
                            <h2>No parsed data available.</h2></>}
                            <h6>Custom integration data</h6>
                            {/* {searcherrs.length > 0 && <div className='text-danger'>{searcherrs}</div>} */}
                            {props?.custom_searchdata?.length > 0 ? <>
                               {props.custom_searchdata.map((item, index) => {
                                let response_data=JSON.parse(item.response_data);
                                let answers=typeof props.exist_data?.search_data!="undefined" ? props.exist_data.search_data : [];
                                let ind=-1;
                                answers=answers ? answers:[];
                                if(answers.length > 0) {
                                   ind=answers.findIndex(itemval=>itemval.name==item.search_field.trim())
                                }
                                let ans=ind>-1 && answers.length>0 ? answers[ind].val :"";
                                return(<div className='form-group' key={item?.search_field}>
                                    <label>Select {item.search_field}</label>
                                    <select name={item.search_field} 
                                    key={index} 
                                    defaultValue={ans}
                                     onChange={(e)=>changeSeachVal(e,item)} 
                                     className='form-control'>
                                    <option value="">Select value for {item.search_field}</option>
                                        {showOptions(response_data)}
                                    </select>
                                    {(ans=="" && searcherrs.length>0) && <div className='text-danger'>{searcherrs}</div>}
                                </div>);
                               })}
                            </>:<p>No data found</p>}
                                            
                                    </div>
                                </div>
                        </form>
                        
                    </div>
                    <div className="modal-footer">
                    <div className="row"><button
            type="button"
            className="btn linear-gradient yr-submitbtn btn-sm"
            onClick={(e)=>handleSubmit(e)}
          >
           {props?.custom_search?.button_name}
          </button> &nbsp;&nbsp;
          <button
            type="button"
            className="btn linear-gradient yr-submitbtn btn-sm"
            onClick={(e)=>closeCustomPopup()} 
          >
            Cancel
          </button></div>
                    </div>

                </div>
                

            </div>
        </div>
    </React.Fragment>
    );
};




export default CustomModel;